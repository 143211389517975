import React from "react";
import { Button, Icon, Classes, H4, Dialog } from "@blueprintjs/core";
import DockerInspect from "./Assets/DockerInspect.png";
import XbinExample from "./Assets/XbinExample.png";
import XbinRun from "./Assets/XbinRun.png";
import { FlexDiv } from "./Layout";

const { useState, useEffect } = React;
// https://stackoverflow.com/questions/64116662/only-show-popup-one-time-with-react-local-storage
function useLocalStorage(key, initialDefault) {
  const [val, setVal] = useState(() => {
    const localStorageVal = localStorage.getItem(key);

    return localStorageVal !== null
      ? JSON.parse(localStorageVal)
      : initialDefault;
  });

  useEffect(() => {
    if (localStorage.getItem(key) === null) {
      setVal(initialDefault);
    }
  }, [key, initialDefault]);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(val));
  }, [val, key]);

  return [val, setVal];
}

export default function Webtour() {
  const [visited, setVisited] = useLocalStorage("home", false);
  const [isOpen, setIsOpen] = React.useState(false);
  React.useEffect(() => {
    if (visited) {
      return;
    }
    console.log("user is the first time to visit xbin.io! welcome!");
    setVisited(true);
    setIsOpen(true);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title="Welcome to Xbin.IO !!"
      icon="crown"
      style={{ width: "70%" }}
    >
      <div className={Classes.DIALOG_BODY}>
        <H4>What is xbin.io?</H4>
        <FlexDiv
          style={{ marginBottom: "16px", justifyContent: "space-between" }}
        >
          <div>
            <p>
              Well, like <code>/bin</code>, <code>/usr/bin</code>, it is where
              your command tool lives, but in the "cloud".
            </p>
            <p>
              Imagine this, you need to check a big json, but you didn't have jq
              installed on the server, What can you do?
            </p>
          </div>
          <img
            src={DockerInspect}
            width="40%"
            style={{ borderRadius: "10px" }}
          />
        </FlexDiv>
        <FlexDiv
          style={{ marginBottom: "16px", justifyContent: "space-between" }}
        >
          <p>
            Well, you can open xbin.io, then type the command here, it will
            generate a curl command(curl is everywhere, right?), then you copy
            the curl command into your terminal.
          </p>
          <img src={XbinExample} width="40%" style={{ borderRadius: "10px" }} />
        </FlexDiv>
        <FlexDiv
          style={{ marginBottom: "16px", justifyContent: "space-between" }}
        >
          <p>Boom! that's it! You are running jq through cURL via xbin.io !!</p>
          <img src={XbinRun} width="40%" style={{ borderRadius: "10px" }} />
        </FlexDiv>
        <Button
          full
          intent="primary"
          style={{ width: "100%" }}
          onClick={handleClose}
        >
          I GET IT, LET'S GO!!
        </Button>
      </div>
    </Dialog>
  );
}
