import { Navbar, NavbarGroup, Classes, Button } from "@blueprintjs/core";
import { useHistory } from "react-router-dom";

export default function AppNavbar(props) {
  const history = useHistory();
  return (
    <Navbar style={{ padding: "0 32px" }}>
      <NavbarGroup>
        <div
          style={{
            width: "100px",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: [
              "BlinkMacSystemFont",
              "Segoe UI",
              "Roboto",
              "Oxygen",
              "Ubuntu",
              "Cantarell",
              "Fira Sans",
              "Droid Sans",
              "Helvetica Neue",
              "sans-sans",
            ],
            fontWeight: 900,
            color: "#fff",
            background: "#24292f",
            cursor: "pointer",
          }}
          onClick={() => history.push("/")}
        >
          xbin.io
        </div>
        <Button
          className={Classes.MINIMAL}
          icon="help"
          text="What is xbin.io?"
          onClick={() => history.push("/w/intro")}
        />
        <Button
          className={Classes.MINIMAL}
          icon="lightbulb"
          text="FAQ and Tips"
          onClick={() => history.push("/w/faq")}
        />
        <Button
          className={Classes.MINIMAL}
          icon="code"
          text="Install"
          onClick={() => history.push("/w/install")}
        />
        <Button
          className={Classes.MINIMAL}
          icon="box"
          text="Available Commands"
          onClick={() => history.push("/w/tool")}
        />
        <Button
          className={Classes.MINIMAL}
          icon="buggy"
          text="Find a bug?"
          onClick={() =>
            (window.location =
              "https://github.com/xbin-io/xbin/issues/new?assignees=&labels=&template=bug_report.md&title=")
          }
        />
      </NavbarGroup>
    </Navbar>
  );
}
