import { H2 } from "@blueprintjs/core";
import Playground from "./Playground";
import { useTitle } from "./Utils";
import Webtour from "./Webtour";

const Home = () => {
  useTitle("Xbin Playground - xbin.io");

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <H2>⭐ Xbin Playground ⭐</H2>
      </div>
      <Playground welcome={true} />
      <Webtour />
    </>
  );
};

export default Home;
