import { H2, H4, H3 } from "@blueprintjs/core";
import { useTitle } from "./Utils";

export default function InstallPage() {
  useTitle("Install Xbin - xbin.io");
  return (
    <div className="infoPage">
      <H2 className="pageInfoHasNext">Install Xbin</H2>

      <p>
        Xbin.io was designed to run commands without installing anything, only
        if you have cURL. However, this script can help you to compose the curl
        command more easily.
      </p>

      <H3>Installation</H3>
      <H4>Bash</H4>
      <div className="pageInfoHasNext">
        <p>
          Copy this function, paste it into your terminal, it will define a
          function called xbin. (If you want xbin to work every time, you need
          to copy it into your <code>~/.bashrc</code>
        </p>

        <a href="https://github.com/xbin-io/xbin/blob/main/xbin.sh">xbin.sh</a>
      </div>
      <H4>Zsh</H4>
      <div className="pageInfoHasNext">
        <p>If you use zsh, copy the code below instead.</p>
        <a
          className="pageInfoHasNext"
          href="https://github.com/xbin-io/xbin/blob/main/xbin.zsh"
        >
          xbin.zsh
        </a>
      </div>

      <H3>Usage</H3>
      <p>
        You may realize that this installation is just to define a function in
        your shell, the function called Xbin, which will generate and run the
        curl command for you. (So don't forget to reload your shell after the
        installation! like <code>. ~/.bashrc</code> )
      </p>
      <p>
        When you want to run a command through xbin.io but didn't install it on
        your laptop, you can just prepend `xbin` before the command.
      </p>
      <p>
        For example, <code>cat config.json | jq .</code> equals to{" "}
        <code>cat config.json | xbin jq .</code>, <code>jq --help</code> equals
        to <code>xbin jq --help</code>.
      </p>

      <pre className="pageInfoHasNext">
        <code>
          {`$ echo "import this" | xbin python -
The Zen of Python, by Tim Peters

Beautiful is better than ugly.
Explicit is better than implicit.
Simple is better than complex.
Complex is better than complicated.
Flat is better than nested.
Sparse is better than dense.
Readability counts.
Special cases aren't special enough to break the rules.
Although practicality beats purity.
Errors should never pass silently.
Unless explicitly silenced.
In the face of ambiguity, refuse the temptation to guess.
There should be one-- and preferably only one --obvious way to do it.
Although that way may not be obvious at first unless you're Dutch.
Now is better than never.
Although never is often better than *right* now.
If the implementation is hard to explain, it's a bad idea.
If the implementation is easy to explain, it may be a good idea.
Namespaces are one honking great idea -- let's do more of those!`}
        </code>
      </pre>
    </div>
  );
}
