
export function FlexDiv(props) {
  const { alignItems, style } = props;
  return (
    <div
      style={{
        display: "flex",
        gap: "8px",
        alignItems: alignItems || "center",
        ...style,
      }}
    >
      {props.children}
    </div>
  );
}
