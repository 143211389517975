import { useHistory } from "react-router-dom";
import { useTitle } from "./Utils";
import { Position, H2, Callout, H3, Card } from "@blueprintjs/core";
import { FlexDiv } from "./Layout";
import { Tooltip2 } from "@blueprintjs/popover2";

export default function ToolList(props) {
  useTitle("Tool List - xbin.io");
  const { tdb } = props;

  let groups = {};
  Object.values(tdb).forEach((tool) => {
    if (tool.group in groups) {
      groups[tool.group].push(tool);
    } else {
      groups[tool.group] = [tool];
    }
  });

  let sortedGroups = Object.keys(groups).sort();
  return (
    <>
      <H2 style={{ textAlign: "center" }}>Xbin.io Tool List</H2>
      <Callout
        className="pageInfoHasNext"
        title="Propose New Tools!"
        icon="clean"
      >
        Didn't find your favorite tool? Don't be sad, tell us by{" "}
        <a href="https://github.com/xbin-io/xbin/issues/new?assignees=&labels=newtool&template=new-tool-request.md&title=%5BNEWTOOL%5D+is+awesome%2C+please+add+it+to+xbin.io%21">
          open an issue here
        </a>
        .
      </Callout>
      {sortedGroups.map((group) => (
        <ToolGroup key={group} tools={groups[group]} group={group} />
      ))}
    </>
  );
}

const ToolGroup = (props) => {
  const { group, tools } = props;
  return (
    <div style={{ marginBottom: "32px" }}>
      <H3> {group} </H3>
      <FlexDiv style={{ gap: "16px", flexWrap: "wrap" }}>
        {tools.map((tool) => (
          <ToolItem key={tool.name} tool={tool} />
        ))}
      </FlexDiv>
    </div>
  );
};
const ToolItem = (props) => {
  const { tool } = props;
  const { name } = tool;

  const history = useHistory();
  let description;
  if (tool.type === "alias") {
    description = `${name} is an alias to ${tool.aliasto}`;
  } else {
    description = tool.description;
  }

  return (
    <Tooltip2
      content={description}
      openOnTargetFocus={false}
      hoverOpenDelay={300}
      placement={Position.TOP}
    >
      <Card
        key={name}
        interactive
        elevation={0}
        style={{ padding: "10px 20px" }}
        onClick={() => history.push(`/w/tool/${name}`)}
      >
        {name}
      </Card>
    </Tooltip2>
  );
};
