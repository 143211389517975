import React from "react";
import ToolDetail from "./ToolDetail";
import ToolList from "./ToolList";
import { Switch, Route } from "react-router-dom";
import axios from "axios";
import { Spinner } from "@blueprintjs/core";


function Tools() {
  const [tdb, setTdb] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    axios.get("/api/all-tools").then((resp) => {
      console.log("Get all tools: ", resp.data);
      setTdb(resp.data);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <Spinner></Spinner>;
  }

  return (
    <Switch>
      <Route path="/w/tool" exact>
        <ToolList tdb={tdb} />
      </Route>
      <Route path="/w/tool/:toolname" exact>
        <ToolDetail tdb={tdb} />
      </Route>
    </Switch>
  );
}

export default Tools;
