import React from "react";
import { XTerm } from "xterm-for-react";
import "./Terminal.css";
import { ansiEscapes as a } from "./AnsiEscape";
import { FitAddon } from "xterm-addon-fit";
import { SUPPORTED, TerminalHelpDialog } from "./TerminalHelpDialog";
import { Button, Icon, Colors } from "@blueprintjs/core";
import { FlexDiv } from "./Layout";

const DEBUG = false;
const fitAddon = new FitAddon();

const baseTheme = {
  foreground: "#F8F8F8",
  background: "#2D2E2C",
  selection: "#5DA5D533",
  black: "#1E1E1D",
  brightBlack: "#262625",
  red: "#CE5C5C",
  brightRed: "#FF7272",
  green: "#5BCC5B",
  brightGreen: "#72FF72",
  yellow: "#CCCC5B",
  brightYellow: "#FFFF72",
  blue: "#5D5DD3",
  brightBlue: "#7279FF",
  magenta: "#BC5ED1",
  brightMagenta: "#E572FF",
  cyan: "#5DA5D5",
  brightCyan: "#72F0FF",
  white: "#F8F8F8",
  brightWhite: "#FFFFFF",
};

const WELCOME = `___   ___ .______    __  .__   __.     __    ______   
\\  \\ /  / |   _  \\  |  | |  \\ |  |    |  |  /  __  \\  
 \\  V  /  |  |_)  | |  | |   \\|  |    |  | |  |  |  | 
  >   <   |   _  <  |  | |  . \`  |    |  | |  |  |  | 
 /  .  \\  |  |_)  | |  | |  |\\   |  __|  | |  \`--'  | 
/__/ \\__\\ |______/  |__| |__| \\__| (__)__|  \\______/  

[38;2;254;69;59mW[39m[38;2;249;36;97me[39m[38;2;231;12;139ml[39m[38;2;201;1;180mc[39m[38;2;163;4;215mo[39m[38;2;122;21;240mm[39m[38;2;80;49;253me[39m[38;2;44;86;252m [39m[38;2;18;128;237mt[39m[38;2;3;169;211mo[39m[38;2;2;206;175m [39m[38;2;15;234;133mx[39m[38;2;40;251;92mb[39m[38;2;75;254;54mi[39m[38;2;116;243;24mn[39m[38;2;158;219;6m.[39m[38;2;196;186;1mi[39m[38;2;227;145;10mo[39m[38;2;248;103;32m![39m[38;2;254;64;64m[39m
`;

export const Terminal = (props) => {
  const { input, setInput, onEnter, inputFile, welcome, initCommand } = props;
  const xtermRef = React.useRef(null);

  const [help, setHelp] = React.useState();
  const generateHelp = React.useCallback(() => {
    // ramdon terminal tip.
    console.log("generate a terminal help...");
    setHelp(SUPPORTED[Math.floor(Math.random() * SUPPORTED.length)]);
  }, []);

  let defaultPrompt = "$ ";
  if (inputFile) {
    defaultPrompt = "$ cat input.txt | ";
  }
  const [promptStr, setPromptStr] = React.useState(defaultPrompt);

  const prompt = React.useCallback(() => {
    const term = xtermRef.current.terminal;
    term.write("\r\n" + promptStr);
  }, [promptStr]);

  React.useEffect(() => {
    if (inputFile) {
      setPromptStr("$ cat input.txt | ");
    } else {
      setPromptStr("$ ");
    }
  }, [inputFile]);

  const [hadGreetings, setHadGreetings] = React.useState(false);
  // on init
  React.useEffect(() => {
    console.log("running effect for terminal...");
    setInput("");
    const term = xtermRef.current.terminal;
    term.focus();

    if (!hadGreetings) {
      fitAddon.fit();

      if (welcome) {
        term.write(WELCOME);
      }
      prompt();

      if (initCommand) {
        // running initCommands...

        setInput(initCommand);

        let sleepMs = (initCommand.length / 3) * 1000; // finish in 5 s
        sleepMs = Math.max(sleepMs, 80); // don't faster than 80ms
        sleepMs = Math.min(sleepMs, 100); // don't slower than 150ms
        console.log("type speed is: ", sleepMs);

        let p = Promise.resolve();
        for (let i in initCommand) {
          p = p
            .then(() => term.write(initCommand[i]))
            .then(() => delay(sleepMs));
        }
        p.then(() => submitCmd(term, initCommand));

        function delay(duration) {
          return new Promise((resolve) => {
            setTimeout(() => resolve(), duration);
          });
        }
      }

      generateHelp();
      setHadGreetings(true);
    } else {
      prompt();
    }
  }, [generateHelp, welcome, promptStr]);

  const [history, setHistory] = React.useState([]);
  const [historyPos, setHistoryPos] = React.useState(0);
  const [line, setLine] = React.useState(0);

  const moveCursorRight = (term, cols, cursorX, currentInput) => {
    // can not exceeding current input
    if (line * cols + cursorX - promptStr.length >= currentInput.length) {
      return;
    }

    if (cursorX < cols - 1) {
      term.write(a.cursorRight());
    } else {
      term.write(a.cursorDownBegin());
      setLine(line + 1);
    }
  };

  const moveCursorLeft = (term, cols, cursorX, line) => {
    if (line === 0) {
      if (cursorX > promptStr.length) {
        term.write(a.cursorLeft());
      }
    } else {
      // have multiple lines
      if (cursorX > 0) {
        term.write(a.cursorLeft());
      } else {
        // move to previous line
        term.write(a.cursorUp() + a.cursorColumn(cols));
        setLine(line - 1);
      }
    }
  };

  const ensureLastLine = (input, cols) => {
    const totalLength = input.length + promptStr.length;
    const newLines = Math.floor(totalLength / cols);
    setLine(newLines);
  };

  const cursorToInputEnd = (term, input, cols, totalLines) => {
    const totalLength = input.length + promptStr.length + 1;
    const newLines = totalLines - line - 1;
    setLine(totalLines - 1);
    const toCol = totalLength - (totalLines - 1) * cols;
    term.write(a.cursorDownBegin(newLines));
    term.write(a.cursorColumn(toCol));
  };

  const cursorToInputBegin = (term) => {
    if (line > 0) {
      term.write(a.cursorUpBegin(line));
      setLine(0);
    }
    term.write(a.cursorColumn(promptStr.length + 1));
  };

  const earseMultilines = (term, line, totalLines) => {
    // line: current line
    // earse the totalLines data. cursor will stop at the beginning of multi line.

    // move cursor to last line
    const linesUntilEnd = totalLines - 1 - line;
    if (linesUntilEnd > 0) {
      term.write(a.cursorDownBegin(linesUntilEnd));
    }
    const earse = Array(totalLines).fill(a.earseEntireLine);
    const upJoined = earse.join(a.cursorUpBegin());
    term.write(upJoined);
  };

  const submitCmd = (term, input) => {
    if (input.replace(/^\s+|\s+$/g, "").length != 0) {
      // Check if string is all whitespace
      const newHis = [...history, input];
      setHistory(newHis);
      setHistoryPos(newHis.length);
      term.write(
        "\r\n" +
          a.yellow +
          "running your command, please wait..." +
          a.resetModes
      );
      if (DEBUG) {
        term.write("\r" + a.earseEntireLine + "->" + input);
        prompt();
      } else {
        let returnValue = onEnter(input);
        returnValue
          .then((resp) => {
            term.write("\r" + a.earseEntireLine);
            term.write(resp.data);
          })
          .catch((err) => {
            term.write("\r" + a.earseEntireLine);
            term.write(a.red + err.response.data + a.resetModes);
            console.log("get error executing command:", err);
          })
          .finally(() => prompt());
      }
    } else {
      prompt();
    }
    setInput("");
    setLine(0);
  };

  const handleKey = ({ key, domEvent }) => {
    console.log(`key :${key}, event: `, domEvent);
    const code = domEvent.keyCode;
    const { key: eventKey, ctrlKey } = domEvent;
    const term = xtermRef.current.terminal;
    const cursorX = term.buffer.active.cursorX;
    const cursorY = term.buffer.active.cursorY;
    const rows = term.rows;
    const cols = term.cols;

    const totalLines = Math.ceil((input.length + promptStr.length + 1) / cols);
    if (code === 13) {
      // Enter key
      submitCmd(term, input);
    } else if (eventKey === "c" && ctrlKey) {
      console.log("ctrl + c pressed!");
      prompt();
      setInput("");
      setLine(0);
    } else if (code === 8) {
      // Backspace
      const inputLen = input.length;
      if (inputLen <= 0) {
        return;
      }
      const totalLines = Math.ceil(
        (input.length + promptStr.length + 1) / cols
      );

      term.write(a.saveCursorPosition);
      earseMultilines(term, line, totalLines);

      const inputIndex = cols * line + cursorX - promptStr.length;
      const newInput = input.slice(0, inputIndex - 1) + input.slice(inputIndex);
      term.write("\r" + promptStr + newInput + a.restoreCursorPosition);
      // move up if needed.
      moveCursorLeft(term, cols, cursorX, line);
      setInput(newInput);
    } else if (code === 37 || (code === 66 && domEvent.ctrlKey)) {
      // Left Arrow || ctrl+B
      moveCursorLeft(term, cols, cursorX, line);
    } else if (code === 38 || (code === 80 && domEvent.ctrlKey)) {
      // Up Arrow || ctrl + P
      if (history.length > 0) {
        let newPos = historyPos;
        if (historyPos > 0) {
          newPos = historyPos - 1;
          setHistoryPos(newPos);
        }
        const target = history[newPos];
        earseMultilines(term, line, totalLines);
        term.write("\r" + promptStr + target);
        ensureLastLine(target, cols);

        setInput(target);
      }
    } else if (code === 39 || (code === 70 && domEvent.ctrlKey)) {
      // Right Arrow || ctrl+F
      moveCursorRight(term, cols, cursorX, input);
    } else if (code === 40 || (code === 78 && domEvent.ctrlKey)) {
      // Down Arrow
      if (history.length > 0) {
        if (historyPos < history.length - 1) {
          const newPos = historyPos + 1;
          setHistoryPos(newPos);
          const target = history[newPos];
          setInput(target);

          earseMultilines(term, line, totalLines);
          term.write("\r" + promptStr + target);
          ensureLastLine(target, cols);
        }
      }
    } else if (code === 76 && domEvent.ctrlKey) {
      // Ctrl+L earse the screen
      // same behaviour with bash, it clears current screen without saving buffer.
      const oldCursorLocation = cursorX;
      term.write(
        a.earseEntireScreen +
          a.cursorHome +
          promptStr +
          input +
          a.cursorHome +
          (line ? a.cursorDownBegin(line) : "") +
          a.cursorColumn(oldCursorLocation + 1)
      );
    } else if (code === 65 && domEvent.ctrlKey) {
      // Ctrl+A move to the beginning of input
      cursorToInputBegin(term);
    } else if (code === 69 && domEvent.ctrlKey) {
      // Ctrl+E move to the end of input
      cursorToInputEnd(term, input, cols, totalLines);
    } else {
      // input printable

      term.write(a.saveCursorPosition);
      earseMultilines(term, line, totalLines);

      const inputIndex = cols * line + cursorX - promptStr.length;
      const newInput =
        input.slice(0, inputIndex) + key + input.slice(inputIndex);
      term.write("\r" + promptStr + newInput + a.restoreCursorPosition);
      // down if needed.
      moveCursorRight(term, cols, cursorX, newInput);
      setInput(newInput);
    }
  };

  const [helpIsOpen, setHelpIsOpen] = React.useState(false);

  return (
    // Create a new terminal and set it's ref.
    <>
      <div className="terminalContainer">
        <XTerm
          ref={xtermRef}
          onKey={handleKey}
          addons={[fitAddon]}
          options={{
            fontFamily: '"Cascadia Code", Menlo, monospace',
            theme: baseTheme,
            cursorBlink: true,
            convertEol: true,
            rows: 35,
            cols: 10,
          }}
        />
      </div>
      <FlexDiv
        style={{
          justifyContent: "space-between",
          alignItems: "baseline",
          marginTop: "4px",
        }}
      >
        <div onClick={() => generateHelp()}>
          <Icon
            icon="lightbulb"
            style={{ marginRight: "4px" }}
            color={Colors.GRAY2}
          />
          {help}
        </div>
        <Button
          onClick={() => setHelpIsOpen(true)}
          small
          outlined
          intent="primary"
        >
          help
        </Button>
      </FlexDiv>
      <TerminalHelpDialog isOpen={helpIsOpen} setIsOpen={setHelpIsOpen} />
      {DEBUG && (
        <div style={{ textAlign: "start" }}>
          <div>
            current input{input.length}: {input}
          </div>
          <div>historyPos: {historyPos}</div>
          <div>
            last cursorX: {xtermRef?.current?.terminal.buffer.active.cursorX}
          </div>
          <div>
            last cursorY: {xtermRef?.current?.terminal.buffer.active.cursorY}
          </div>

          <div>current line: {line}</div>
          <div>term rows: {xtermRef?.current?.terminal.rows}</div>
          <div>term cols: {xtermRef?.current?.terminal.cols}</div>
        </div>
      )}
    </>
  );
};
