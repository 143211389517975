import React from "react";
import { Colors, Icon, Button, H6, Card } from "@blueprintjs/core";
import { FlexDiv } from "./Layout";
import { Popover2 } from "@blueprintjs/popover2";
import { useSpring, animated } from "react-spring";

const HOST = window.location.protocol + "//" + window.location.host;

const generateCurl = (command) => {
  const { cmd, args, needStdin, returncode } = command;
  let curl = `curl -X POST`;
  if (needStdin) {
    curl += ` --data-binary @-`;
  }
  curl += ` ${HOST}/${cmd}`;
  if (args) {
    let cleanArgs = args.replaceAll('\\', '\\\\');
    cleanArgs = cleanArgs.replaceAll('"', '\\"');
    cleanArgs = cleanArgs.replaceAll('$', '\\$');
    cleanArgs = cleanArgs.replaceAll('!', '\\!');
    curl += ` -H "X-Args: ${cleanArgs}"`;
  }
  if (returncode) {
    curl += ` -H "X-Print-Return-Code: ${returncode}"`;
  }
  return curl;
};

export default function CopyCurl(props) {
  const { lastCommand, currentCommand } = props;

  const currentCurl = generateCurl(currentCommand);
  let lastCurl;
  if (lastCommand.cmd) {
    lastCurl = generateCurl(lastCommand);
  } else {
    lastCurl =
      "Your executed command will be converted to a cURL command and display here...";
  }
  return (
    <>
      <div className="copyCurlZone">
        <CommandZone
          title="Equivalent of Last Running Command"
          needStdin={lastCommand.needStdin}
          command={lastCurl}
        />
        <CommandZone
          title="Equivalent cURL Command"
          command={currentCurl}
          needStdin={currentCommand.needStdin}
        />
      </div>
    </>
  );
}

// https://stackoverflow.com/a/33928558/6931919
// Copies a string to the clipboard. Must be called from within an
// event handler such as click. May return false if it failed, but
// this is not always possible. Browser support for Chrome 43+,
// Firefox 42+, Safari 10+, Edge and Internet Explorer 10+.
// Internet Explorer: The clipboard feature may be disabled by
// an administrator. By default a prompt is shown the first
// time the clipboard is used (per session).
function copyToClipboard(text) {
  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData("Text", text);
  } else if (
    document.queryCommandSupported &&
    document.queryCommandSupported("copy")
  ) {
    var textarea = document.createElement("textarea");
    textarea.textContent = text;
    textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand("copy"); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn("Copy to clipboard failed.", ex);
      return prompt("Copy to clipboard: Ctrl+C, Enter", text);
    } finally {
      document.body.removeChild(textarea);
    }
  }
}

const CommandZone = (props) => {
  const { command, title, needStdin } = props;

  const [copied, setCopied] = React.useState(false);
  const [renderIndex, setRenderIndex] = React.useState(1);
  const [animation, setAnimation] = React.useState(false);
  const commandToClipboard = () => {
    copyToClipboard(command);
    setCopied(true);
    setRenderIndex(renderIndex + 1);
    setAnimation(true);
    setTimeout(() => {
      setCopied(false);
      setAnimation(false);
    }, 3000);
    setTimeout(() => {
      setAnimation(false);
    }, 700);
  };

  console.log(animation);
  return (
    <Card className={["hasNextItem"]}>
      <H6>
        {title}
        <Popover2 content={<CurlCommandExplain />}>
          <Icon
            icon="help"
            iconSize={15}
            style={{ marginLeft: "4px" }}
            color={Colors.GRAY2}
          />
        </Popover2>
      </H6>
      <div className="curlCommand">
        <pre>
          <code>
            {needStdin && <span>cat input.txt | </span>}
            <CopiableCommand
              cmd={command}
              key={renderIndex}
              shot={animation}
            ></CopiableCommand>
          </code>
        </pre>
      </div>

      <FlexDiv style={{ justifyContent: "end" }}>
        <Button
          small
          outlined
          intent={copied ? "success" : "default"}
          icon={copied ? "small-tick" : null}
          onClick={commandToClipboard}
        >
          {copied ? "Copied" : "Copy cURL"}
        </Button>
      </FlexDiv>
    </Card>
  );
};

const CurlCommandExplain = () => (
  <div style={{ padding: "16px", maxWidth: "300px" }}>
    <H6>Copy this command, and run it in your terminal!</H6>
    <p>
      This <code>cURL</code> command has the same effect as your typed in
      terminal. You can also paste the command after a pipe (
      <code> cat xxx | curl ...</code>
      ).
    </p>
  </div>
);

const CopiableCommand = React.memo(({ cmd, shot }) => {
  const style = useSpring({
    from: { background: "orange" },
    to: [
      { background: "#FFA500ff" },
      { background: "#FEAE1Bff" },
      { background: "#FDB737ff" },
      { background: "#FCC052ff" },
      { background: "#FBC96Dff" },
      { background: "#FAD289ff" },
      { background: "#F9DBA4ff" },
      { background: "#F8E4BFff" },
      { background: "#F7EDDBff" },
      { background: "#F6F6F6ff" },
    ],
    config: { duration: 70 },
  });

  return <animated.span style={shot ? style : null}>{cmd}</animated.span>;
});
