import { H2, H4 } from "@blueprintjs/core";
import { useTitle } from "./Utils";
import { Link } from "react-router-dom";

export default function FAQ() {
  useTitle("FAQ - xbin.io");
  return (
    <div className="infoPage">
      <H2 className="pageInfoHasNext">Frequently Asked Questions</H2>

      <div className="pageInfoHasNext">
        <H4>The curl command is too complicated to compose!</H4>
        <p>
          well, you can use <Link to="/">xbin.io Playground</Link> to help you
          get the curl command, it will be generated on the right of the
          playground and ready for you to copy.
        </p>
        <p>
          However, if you use a command frequently, you can use clipboard
          management tools, like{" "}
          <a href="https://github.com/Clipy/Clipy">Clipy</a> ,
          <a href="https://pastenow.app/">PasteNow</a>, or{" "}
          <a href="https://www.google.com/search?q=clipboard+manager">more</a> ,
          to save the curl command into your clipboard tool, then next time you
          can easily paste it into your terminal.
        </p>
        <p>
          You can also check our <Link to="/w/install">install page</Link>,
          there is a script that can define a <code>Xbin</code> function in your
          shell, Xbin command can help you generate and run the curl command.
          You can also save this script (actuall it is a cat command which will
          define a function in your shell.) into your favorite clipboard tool,
          when you want to install Xbin in a new server, you can just run the
          cat command.
        </p>
      </div>

      <div className="pageInfoHasNext">
        <H4>
          Does xbin.io work like a real PIPE? Or does it just buffer all content
          of my request, run a command, then send me the whole response?{" "}
        </H4>
        <p>
          Of course, it is a real PIPE! The whole idea is about PIPE, after all.
          You can test via this command: So you will see a timestamp printed
          every second instead of waiting for 5 seconds and then seeing the
          whole output.
        </p>
        <pre className="hasNextItem">
          <code>
            echo 'import time;\nfor i in range(5):\n print(time.time(),
            flush=True)\n time.sleep(1)' | curl -X POST --data-binary @-
            http://xbin.io/python -H "X-Args: -"
          </code>
        </pre>
        <p>
          So that we can do real PIPE coll stuff, like we can send xbin.io a
          200M size file, compute the shasum, then xbin.io doesn't need to cache
          all of the files into its memory.
        </p>
      </div>

      <div className="pageInfoHasNext">
        <H4>
          To use the tools that xbin.io provides, I have to send my data to it,
          is it safe?
        </H4>
        <p>
          Yes and no. xbin.io will not permanently save any data, it just prints
          some logs for debugging purposes, and logs will be rotated, the logs
          only contain command name, command args, and stdin will not be logged.
        </p>
        <p>
          However, in principle, you shouldn't send anything credential to the
          public internet, like your company's password, your bank account, etc.
        </p>
      </div>

      <div className="pageInfoHasNext">
        <H4>
          There is a tool I like very much, can you add this tool to xbin.io?
        </H4>
        <p>
          Yes, you can{" "}
          <a href="https://github.com/xbin-io/xbin/issues/new?assignees=&labels=newtool&template=new-tool-request.md&title=%5BNEWTOOL%5D+is+awesome%2C+please+add+it+to+xbin.io%21">
            submit it here
          </a>
          .
        </p>
      </div>
    </div>
  );
}
