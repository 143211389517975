import { H2, H6 } from "@blueprintjs/core";
import { useParams } from "react-router-dom";
import Playground from "./Playground";
import { useTitle } from "./Utils";

export default function ToolDetail(props) {
  const { toolname } = useParams();
  const { tdb } = props;

  useTitle(`online ${toolname} - xbin.io`);
  let cmd = tdb[toolname];
  let aliasDescription = null;
  if (cmd.type === "alias") {
    const target = cmd.aliasto;
    cmd = tdb[target];
    aliasDescription = `${toolname} is just an alias to ${target}, you can run ${toolname} command, and you can also run ${target} command, they will have the same effect.`;
  }

  const { input, command } = cmd.example;

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <H2>{toolname}</H2>
        <H6 className="bp4-text-muted">{cmd.description}</H6>
        {aliasDescription && (
          <H6 className="bp4-text-muted">{aliasDescription}</H6>
        )}
      </div>
      <Playground initCommand={command} exampleInput={input} />
    </div>
  );
}
