import "./App.css";
import AppNavbar from "./AppNarbar";
import Tools from "./Tools";
import Home from "./Home";
import { FocusStyleManager } from "@blueprintjs/core";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import FAQ from "./FAQ";
import InstallPage from "./InstallPage";
import WhatisXbin from "./WhatIsXbin";

FocusStyleManager.onlyShowFocusOnTabs();

function App() {
  return (
    <div className="App">
      <Router>
        <AppNavbar />
        <div className="outMost">
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/w/tool">
              <Tools />
            </Route>
            <Route path="/w/faq">
              <FAQ />
            </Route>
            <Route path="/w/intro">
              <WhatisXbin />
            </Route>
            <Route path="/w/install">
              <InstallPage />
            </Route>
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
