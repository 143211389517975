import { Icon, Classes, H4, Dialog } from "@blueprintjs/core";

export const SUPPORTED = [
  <span>
    Press <kbd>Ctrl</kbd>+<kbd>L</kbd> to clear the screen.
  </span>,
  <span>
    Press <kbd>Ctrl</kbd>+<kbd>C</kbd> cancel the current input command.
  </span>,
  <span>
    Press <kbd>Ctrl</kbd>+<kbd>A</kbd> to go to the beginning of the input.{" "}
  </span>,
  <span>
    Press <kbd>Ctrl</kbd>+<kbd>E</kbd> to go to the end of the input.{" "}
  </span>,
  <span>
    Press <kbd>Ctrl</kbd>+<kbd>B</kbd> to move cursor left.
  </span>,
  <span>
    Press <kbd>Ctrl</kbd>+<kbd>F</kbd> to move cursor right.
  </span>,
  <span>
    <kbd>Ctrl</kbd>+<kbd>P</kbd> or <kbd>↑</kbd>: Previous command in history.
  </span>,
  <span>
    <kbd>Ctrl</kbd>+<kbd>N</kbd> or <kbd>↓</kbd>: Next command in history.
  </span>,
];

export const UNSUPPORTED = [
  <span>
    Pipe is not supported, you can check the "Stdin from pipe" checkbox to set a{" "}
    <code>stdin</code> for your command, but you can not use <code>|</code> to
    redirect the output in this shell. (But nothing stops you doing this in your
    local terminal with the generated cURL command!
  </span>,
  <span>
    Nested commands are not supported, you can only run one command at a time.
  </span>,
  <span>Interative GUI programs like Vim are not supported.</span>,
];

export function TerminalHelpDialog(props) {
  const { isOpen, setIsOpen } = props;

  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title="Terminal Usage Tips"
      icon="ninja"
    >
      <div className={Classes.DIALOG_BODY}>
        <p>
          Xbin Playground Shell is just a simple shell, you can test commands
          here, copy the generated cURL command to your terminal. It doesn't
          have fancy features like <code>zsh</code> or <code>bash</code>, but it
          should be enough.
        </p>
        <H4>Supported Features:</H4>
        {SUPPORTED.map((s, index) => (
          <div key={index} style={{ margin: "4px" }}>
            <Icon icon="heart" style={{ marginRight: "4px" }} color="#E76A6E" />
            {s}
          </div>
        ))}

        <H4>Unsupported:</H4>
        {UNSUPPORTED.map((s, index) => (
          <div key={index} style={{ margin: "4px" }}>
            <Icon
              icon="heart-broken"
              style={{ marginRight: "4px" }}
              color="#EC9A3C"
            />
            {s}
          </div>
        ))}
      </div>
      <div className={Classes.DIALOG_FOOTER} style={{ color: "#8F99A8" }}>
        Find a bug of this little shell? Please{" "}
        <a href="https://github.com/xbin-io/xbin/issues/new">tell me!</a>
      </div>
    </Dialog>
  );
}
