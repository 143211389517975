import React from "react";
import { Terminal } from "./Terminal";
import { Label, Button, Checkbox, Intent, TextArea } from "@blueprintjs/core";
import { FlexDiv } from "./Layout";
import CopyCurl from "./CopyCurl";
import axios from "axios";

const splitCmdArgs = (wholeCmd) => {
  const cleanInput = wholeCmd.trim();
  const firstSpace = cleanInput.indexOf(" ");
  let cmd, args;
  if (firstSpace === -1) {
    cmd = cleanInput;
    args = null;
  } else {
    cmd = cleanInput.substring(0, firstSpace);
    args = cleanInput.substring(firstSpace + 1);
  }
  return [cmd, args];
};

export default function Playground(props) {
  const { welcome, initCommand, exampleInput } = props;

  let defaultNeedStdin = false;
  let defaultStdin = "";
  if (exampleInput && exampleInput.length > 0) {
    defaultNeedStdin = true;
    defaultStdin = exampleInput;
  }
  const [needStdin, setNeedStdin] = React.useState(defaultNeedStdin);
  const [stdin, setStdin] = React.useState(defaultStdin);
  const [input, setInput] = React.useState("");
  const [foldInput, setFoldInput] = React.useState(false);
  const [returncode, setReturncode] = React.useState(false);
  const [lastCommand, setLastCommand] = React.useState({});

  React.useEffect(() => {
    if (exampleInput && exampleInput.length > 0) {
      setFoldInput(true);
    }
  }, [exampleInput]);
  const [cmd, args] = splitCmdArgs(input);
  console.log(`cmd: ${cmd}, args: ${args}`);

  const onEnter = (inputCmd) => {
    console.log("enter hit!");

    const [cmd, args] = splitCmdArgs(inputCmd);
    setLastCommand({
      cmd,
      args,
      returncode,
      needStdin,
    });

    console.log(`input value:  ${cmd}  |  ${args}`);
    let axiosRequest = {
      url: `/${cmd}`,
      headers: { "Content-Type": "text/plain" },
      method: "post",
      transformResponse: (r) => r,
    };

    if (needStdin) {
      axiosRequest.data = stdin;
    }

    if (args) {
      axiosRequest.headers["X-Args"] = args;
    }
    if (returncode) {
      axiosRequest.headers["X-Print-Return-Code"] = "true";
    }

    return axios(axiosRequest);
  };

  const stdinLines = (stdin.match(/\n/g) || "").length + 1;
  return (
    <>
      <FlexDiv alignItems="start" className="terminalControl">
        <div style={{ textAlign: "start", flexGrow: 1 }}>
          <Checkbox
            checked={returncode}
            onChange={(e) => setReturncode(e.target.checked)}
          >
            Print return code
          </Checkbox>
          <Checkbox
            checked={needStdin}
            onChange={(e) => setNeedStdin(e.target.checked)}
          >
            Stdin from pipe
          </Checkbox>
          {needStdin && (
            <>
              <Label htmlFor="input-txt" style={{ marginBottom: 0 }}>
                input.txt
                <TextArea
                  rows={3}
                  id="input-txt"
                  small
                  fill
                  growVertically={!foldInput}
                  large={true}
                  intent={Intent.PRIMARY}
                  onChange={(e) => setStdin(e.target.value)}
                  value={stdin}
                  style={{
                    fontFamily: '"Cascadia Code", Menlo, monospace',
                  }}
                />
              </Label>
              {stdin && stdinLines > 3 && (
                <Button
                  style={{
                    paddingLeft: 0,
                    paddingRight: "8px",
                  }}
                  minimal
                  icon={foldInput ? "caret-down" : "caret-up"}
                  onClick={() => setFoldInput(!foldInput)}
                >
                  {foldInput ? "Expand input.txt" : "Fold input.txt"}
                </Button>
              )}
            </>
          )}
        </div>
        <div className="copyCurlZone"></div>
      </FlexDiv>

      <FlexDiv
        alignItems="start"
        style={{
          marginTop: "16px",
        }}
      >
        <div
          style={{
            flexGrow: 1,
          }}
        >
          <Terminal
            input={input}
            setInput={setInput}
            onEnter={onEnter}
            inputFile={needStdin}
            welcome={welcome}
            initCommand={initCommand}
          />
        </div>
        <CopyCurl
          lastCommand={lastCommand}
          currentCommand={{
            cmd,
            args,
            needStdin,
            returncode,
          }}
        />
      </FlexDiv>
    </>
  );
}
