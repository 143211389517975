import { H2, H4 } from "@blueprintjs/core";
import { useTitle } from "./Utils";

export default function WhatisXbin() {
  useTitle("What is Xbin.io? - xbin.io");
  return (
    <div className="infoPage">
      <H2 className="pageInfoHasNext">What is xbin.io?</H2>
      <div className="pageInfoHasNext">
        <p>
          So there is one college of mine is maintaining our Etcd cluster in our
          company, the for some operations he needs to get the revision from
          Etcd. This is his script:
        </p>
        <pre className="hasNextItem">
          <code>
            rev=$(ETCDCTL_API=3 etcdctl --endpoints=127.0.0.01:2379 endpoint
            status --write-out="json" | egrep -o '"revision":[0-9]*' | egrep -o
            '[0-9].*')
          </code>
        </pre>
        <p>
          There are two <code> egrep </code> just to get the value of a json's
          value, it we use
          <code> jq </code>, it would be something like this:
        </p>
        <pre className="hasNextItem">
          <code>
            rev=$(ETCDCTL_API=3 etcdctl --endpoints=127.0.0.01:2379 endpoint
            status --write-out="json" | jq .revision)
          </code>
        </pre>
        <p>
          It is much simpler and has fewer chances to make a mistake. And that's
          the Unix way of doing work, one good tool for one job.
        </p>
        <p>
          But the thing is, jq is not installed in our servers by default. So I
          am wondering, jq only takes an input and give you the output back, is
          it necessary to install it in order to use it?
        </p>
        <p>
          So that's the idea, with xbin.io, you can PIPE your output to a
          <code> cURL </code>
          command, then get the output. If you have
          <code> cURL </code>
          (which is installed by default in most Linux distros), you have
          everything.
        </p>
        <p>
          You can think of xbin.io as a serverless shell, or a "serverless Linux
          distribution", it has a lot of tools that you can use without
          installing. The tools that xbin.io provides work like this: it takes
          an input, give you the output. So:
        </p>
        <ol>
          <li>TUI tools like htop, vim, ncdu, won't work here.</li>
          <li>
            REPL which needs your interactive input, won't work here. (But for
            most of the REPL you can pass the input through PIPE)
          </li>
          <li>
            Tools that need data besides stdin, like netstat/ps, won't work
            here.
          </li>
        </ol>
        That's it, that's xbin.io. If you have more ideas about this, you are
        welcome to discuss them with us.
      </div>
    </div>
  );
}
