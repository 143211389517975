// https://gist.github.com/fnky/458719343aabd01cfb17a3a4f7296797
//
const ESC = "\x1b";

const ansiEscapes = {};

// Cursor
ansiEscapes.cursorLeft = (count = 1) => ESC + `[${count}D`;
ansiEscapes.cursorRight = (count = 1) => ESC + `[${count}C`;
ansiEscapes.cursorUp = (count = 1) => ESC + `[${count}A`;
ansiEscapes.cursorDown = (count = 1) => (count ? ESC + `[${count}B` : "");
ansiEscapes.cursorUpBegin = (count = 1) => ESC + `[${count}F`;
ansiEscapes.cursorDownBegin = (count = 1) => (count ? ESC + `[${count}E` : "");
ansiEscapes.saveCursorPosition = ESC + "7";
ansiEscapes.restoreCursorPosition = ESC + "8";
ansiEscapes.cursorHome = ESC + "[H";
ansiEscapes.cursorColumn = (count = 1) => ESC + `[${count}G`;

// Erase Functions
ansiEscapes.earseEntireLine = ESC + "[2K";
ansiEscapes.earseEntireScreen = ESC + "[2J";

// color
ansiEscapes.blink = ESC + "[5m";
ansiEscapes.resetblink = ESC + "[25m";
ansiEscapes.yellow = ESC + "[33m";
ansiEscapes.yellowBlink = ESC + "[5;33m";
ansiEscapes.red = ESC + "[31m";

ansiEscapes.resetModes = ESC + "[0m";

// scroll
ansiEscapes.scrollUp = (count = 1) => ESC + `[${count}S`;

export { ansiEscapes };
